export const fields = [
  "amountFunded",
  "deployer",
  "startDay",
  "lat",
  "long",
  "target",
  "message",
  "contentType",
  "fundingDeadline",
  "fundingTarget",
  "provider",
  "proposer",
  "url",
  "isMintingEnabled",
];
